import React, { useRef, useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [selectForm, setSelectForm] = useState("");
  function onChange(value) {
    console.log("Captcha value:", value);
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form.current);

    emailjs
      .sendForm(
        "service_cs9pleq",
        "template_uevc4mr",
        e.target,
        "qeF8ipKt8al_tV16A"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message Sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    // emailjs.sendForm('service_j6h77oi', 'template_m2pdq29', e.target, 'lTLtVIwU3FSF2Z6_C')
    //     .then((result) => {
    //         console.log(result.text);
    //         alert("Message Send")
    //     }, (error) => {
    //         console.log(error.text);
    //     });
    // e.target.reset()
  };
  return (
    <div className="contact_us" ref={form} onSubmit={sendEmail}>
      <div className="header_contact">
        <h1>
          Let’s talk about your next project.
          <br /> How can we help?
        </h1>
        {/* <form className="form_contact">
          <div className="container_from">
            <div className="container">
              <div className="contact ">
                <div className="field is-grouped">
                  <div className="control  " style={{ width: "50%" }}>
                    <input
                      className="input input is-large"
                      name="name"
                      id="name"
                      type="text"
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="control" style={{ width: "50%" }}>
                    <input
                      className="input input is-large"
                      name="company"
                      id="company"
                      type="text"
                      placeholder="Company"
                    />
                  </div>
                </div>

                <div className="field is-grouped ">
                  <div className="control" style={{ width: "50%" }}>
                    <input
                      name="email"
                      className="input input is-large"
                      id="email"
                      type="email"
                      placeholder="Email"
                    />
                  </div>

                  <div className="control" style={{ width: "50%" }}>
                    <input
                      name="jobTitle"
                      id="jobTitle"
                      className="input input is-large  input-white"
                      type="text"
                      placeholder="Job title"
                    />
                  </div>
                </div>

                <div class="select is-large ">
                  <select
                    name="select"
                    id="select"
                    className="select_color"
                    style={{ backgroundColor: "#1b2c49" }}
                    onChange={(e) => setSelectForm(e.target.value)}
                  >
                    <option>I would like to talk more about</option>
                    <option value="Cybersecurity">Cybersecurity</option>
                    <option value="Pro Services">Pro Services</option>
                    <option value="IT Infrastructure">IT Infrastructure</option>
                    <option value="Cloud / Data Center">
                      Cloud / Data Center
                    </option>
                    <option value="Telco / SDWAN/ SASE">
                      Telco / SDWAN/ SASE
                    </option>
                    <option value="UCaaS / CCaaS">UCaaS / CCaaS</option>
                  </select>
                </div>
              </div>

              <div className="bu_submit">
                <input
                  type="submit"
                  value="Send"
                  class="button-34"
                  role="button"
                />
              </div>
            </div>
          </div>
        </form> */}
        <div className="main_form_contact">
          <form style={{ width: "43vh" }}>
            <input
              name="name"
              id="name"
              type="text"
              class="feedback-input"
              placeholder="Full Name"
            />
            <input
              name="email"
              type="text"
              class="feedback-input"
              placeholder="Email"
            />
            <input
              type="text"
              name="company"
              id="company"
              class="feedback-input"
              placeholder="Company"
            />
            <details className="custom-select">
              <summary className="radios">
                <input
                  type="radio"
                  name="item"
                  id="default"
                  title="I would like to talk more about"
                  checked
                />
                {/* <option>I would like to talk more about</option>
                    <option value="Cybersecurity">Cybersecurity</option>
                    <option value="Pro Services">Pro Services</option>
                    <option value="IT Infrastructure">IT Infrastructure</option>
                    <option value="Cloud / Data Center">
                      Cloud / Data Center
                    </option>
                    <option value="Telco / SDWAN/ SASE">
                      Telco / SDWAN/ SASE
                    </option>
                    <option value="UCaaS / CCaaS">UCaaS / CCaaS</option> */}
                <input
                  type="radio"
                  name="item"
                  id="item1"
                  title="Cybersecurity"
                />
                <input
                  type="radio"
                  name="item"
                  id="item2"
                  title="Pro Services"
                />
                <input
                  type="radio"
                  name="item"
                  id="item3"
                  title="IT Infrastructure"
                />
                <input
                  type="radio"
                  name="item"
                  id="item4"
                  title="Cloud / Data Center"
                />
                <input
                  type="radio"
                  name="item"
                  id="item5"
                  title="Telco / SDWAN/ SASE"
                />
              </summary>
              <ul className="list">
                <li>
                  <label htmlFor="item1">
                    Cybersecurity
                    <span />
                  </label>
                </li>
                <li>
                  <label htmlFor="item2">Pro Services</label>
                </li>
                <li>
                  <label htmlFor="item3">IT Infrastructure</label>
                </li>
                <li>
                  <label htmlFor="item4"> Cloud / Data Center</label>
                </li>
                <li>
                  <label htmlFor="item5"> Telco / SDWAN/ SASE</label>
                </li>
              </ul>
            </details>
            <input
              name="phone"
              type="text"
              class="feedback-input"
              placeholder="Job Title"
            />

            {/* 
            <select
              name="select"
              id="select"
              className="select_color"
              style={{ backgroundColor: "#1b2c49" }}
              onChange={(e) => setSelectForm(e.target.value)}
            >
              <option>I would like to talk more about</option>
              <option value="Cybersecurity">Cybersecurity</option>
              <option value="Pro Services">Pro Services</option>
              <option value="IT Infrastructure">IT Infrastructure</option>
              <option value="Cloud / Data Center">Cloud / Data Center</option>
              <option value="Telco / SDWAN/ SASE">Telco / SDWAN/ SASE</option>
              <option value="UCaaS / CCaaS">UCaaS / CCaaS</option>
            </select> */}

            <input type="submit" value="SUBMIT" />
          </form>
          {/* <form className="form_contact">
            <div class="container">
              <div class="row input-container">
                <div class="col-xs-12">
                  <div class="styled-input wide">
                    <input type="text" required />
                    <label>Name</label>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="styled-input">
                    <input type="text" required />
                    <label>Email</label>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="styled-input" style={{ float: "right" }}>
                    <input type="text" required />
                    <label>Phone Number</label>
                  </div>
                </div>
                <div class="col-xs-12">
                  <div class="styled-input wide">
                    <textarea required></textarea>
                    <label>Message</label>
                  </div>
                </div>
                <div class="col-xs-12">
                  <div class="btn-lrg submit-btn">Send Message</div>
                </div>
              </div>
            </div>
          </form> */}
        </div>
      </div>
    </div>
  );
};

export default Contact;

// Tank you for contacting us. We will  handle your request as soon as possible.

//  <p>
//                                     <label>Name</label>
//                                     <input type="text" name="name" id="name" required="" />
//                                 </p>
//                                 <p>
//                                     <label>Company</label>
//                                     <input type="text" name="company" id="company" />
//                                 </p>
//                                 <p>
//                                     <label>E-mail Address</label>
//                                     <input type="email" name="email" id="email" required="" />
//                                 </p>
//                                 <p>
//                                     <label>Phone Number</label>
//                                     <input type="text" name="phone" id="phone" />
//                                 </p>
//                                 <p className="full">
//                                     <label>Message</label>
//                                     <textarea rows={5} name="message" id="message" defaultValue={""} />
//                                 </p>
//                                 <p className="full">
//                                     <button type="submit" value="Send">Submit</button>
//                                 </p>
