import React, { useRef } from "react";
import "./WhatWeDo.css";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

const WhatWeDo = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_j6h77oi",
        "template_m2pdq29",
        form.current,
        "lTLtVIwU3FSF2Z6_C"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Send");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <div className="aboutus">
      {/* <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "#FFFFFF",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 1,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#5292D7",
            },
            links: {
              color: "#7E71C2",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              directions: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1.5,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 2000,
              },
              value: 70,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
      ></Particles> */}
      <div className="about">
        <div className="header is-size-1-tablet is-size-1-mobile">
          <h1 className="has-text-centered"> What We Do</h1>

          {/* <button class="button-69" role="button"> <Link to='about' style={{ "color": "white" }}>See More</Link> <Icon icon="ant-design:arrow-right-outlined" /></button> */}
        </div>
        <div className="whatwedo_text">
          <p>
            <p>
              We are a leading technology consulting company delivering tailored
              IT, Cybersecurity, IT sourcing, Cloud, Telco and Voice consultancy
              services to all businesses of all sizes with focus on Mid-Market
              to Enterprise.
            </p>
            <p>
              We partner with our clients to understand their business
              challenges and future goals, advise, and guide them toward the
              right solutions by formulating IT strategies and implementing
              innovative technologies.
            </p>
            <p>
              We are committed to creating state-of-the-art client experience
              and success, striving to offer our partners a fair competitive
              edge through IT.
            </p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
