import React, { useRef } from "react";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navbar/NavBar";
import Verticals from "./components/verticals/Verticals";
import WhatWeDo from "./components/whatwedo/WhatWeDo";
import OurExpertise from "./components/ourexpertise/OurExpertise";
import WhatWeOffer from "./components/whatweoffer/WhatWeOffer";
import logo from "../../data/image/logo/1.jpeg";
import { Icon } from "@iconify/react";
import video from "../../data/video/main/istockphoto-1270058174-640_adpp_is.mp4";
import { Link } from "react-router-dom";
import "./Home.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import OurProcess from "./components/ourprocess/OurProcess";
import Reviews from "./components/reviews/Reviews";
import Contact from "./components/contact/Contact";
import AboutUs from "./components/aboutus/AboutUs";
import ourExper from "./components/ourexpertise/ourExper";

// Import Swiper styles

const Home = () => {
  let section = document.querySelectorAll("section");
  let menu = document.querySelectorAll("header nav a");

  window.onscroll = () => {
    section.forEach((i) => {
      let top = window.scrollY;
      let offset = i.offsetTop - 150;
      let height = i.offsetHeight;
      let id = i.getAttribute("id");

      if (top >= offset && top < offset + height) {
        menu.forEach((link) => {
          link.classList.remove("active");
          document
            .querySelector("header nav a[href*=" + id + "]")
            .classList.add("active");
        });
      }
    });
  };

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  window.addEventListener("scroll", reveal);

  // To check the scroll position on page load
  reveal();

  return (
    <>
      <header>
        <Link to="/">
          <img className="logo" src={logo} />
        </Link>
        <nav ref={navRef}>
          <a href="#whatwedo"> What We Do</a>

          <a href="#expertise">Expertise</a>
          <a href="#verticals">Verticals</a>
          <a href="#aboutus">About Us</a>

          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <Icon icon="bi:x-lg" />
          </button>
          <a href="#contact">
            <button class="button-60" role="button">
              Contact Us
            </button>
          </a>
        </nav>

        <button className="nav-btn bu" onClick={showNavbar}>
          <Icon className="nav-btn nav-close-btn" icon="ci:hamburger" />
        </button>
      </header>
      <section id="home">
        <div className="information_swiper">
          <video
            src={video}
            autoPlay={true}
            loop={true}
            controls={false}
            playsInline
            muted
            id="video"
          />
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            spaceBetween={50}
            // centeredSlides={true}
            autoplay={{
              delay: 10000,
              // disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            effect={"fade"}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="low_slider">
                <h1>Your success is our first priority </h1>

                <p>
                  Our commitment is to our customers- that's why we tailor
                  solutions based on your needs, and not on any product bias. We
                  will go above and beyond to ensure your success.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="two_slider">
                <h1>Our straegy and model </h1>

                <p>
                  Is to leverage our resources and experience in the same
                  vertical to expedite the decision-making timeline. We listen
                  to your needs and guide you down the path of digital
                  transformation, working with you together throughout the
                  process. We are part of the digital revolution, and we are
                  highly invested in automation, and a superior digital toolset.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="two_slider">
                <h1>Why us </h1>

                <p>
                  We partner with you and your IT team to bolster efficiency and
                  allow you to focus more on results. We leverage talent,
                  expertise, creativity, innovation, and service to provide you
                  with a winning formula to grow your business and give you an
                  edge over your competitors. This is achieved through the
                  reduction of management costs and increase of quality and
                  efficacy of your company’s IT, and cybersecurity posture.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section id="whatwedo">
        <div className="container reveal">
          <WhatWeDo />
        </div>
      </section>
      <section id="expertise">
        <div className="container reveal">
          <OurExpertise />
        </div>
      </section>
      <section id="verticals">
        <div className=" ">
          <Verticals />
        </div>
      </section>
      <section id="ourprocess">
        <div className="container reveal">
          <OurProcess />
        </div>
      </section>
      <section id="contact">
        <div className="container reveal">
          <Contact />
        </div>
      </section>
      {/* 
      <section id="aboutus">
        <div className="container reveal">
          <AboutUs />
        </div>
      </section> */}

      <section id="aboutus">
        <div className="container reveal">
          <AboutUs />
        </div>
      </section>
      {/*
       */}
      {/* <section id="about">
                <div className="container reveal">
                    <AboutUs />

                </div>
            </section> */}
      {/* <section id="services">
                <div className="container reveal">
                    <OurExpertise />
                </div>
            </section>
            <section id="contact">
                <div className="container reveal">
                    <Verticals />
                </div>
            </section>
    
            <Footer /> */}
      {/* <Slider /> */}
      {/* <div id='about'>
                <AboutUs />
            </div>
            <div id='ourexpertise'>
                <OurExpertise />
            </div>
            <div id='verticals'>
                <Verticals />
            </div>
            <div id='ourprocess'>
                <OurProcess />
            </div> */}
      {/* <div id='reviews'>
                <Reviews />
            </div> */}

      {/* <div id='contact_ca'>
                <Contact />
            </div> */}
    </>
  );
};

export default Home;
