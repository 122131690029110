import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div className="aboutus_main">
      <div className="aboutus_header">
        <h1>ABOUT US</h1>
      </div>
      <div className="container">
        <p>
          <p>
            We are a woman-owned business locate in Chicago area.
            <br></br> Our success-oriented strategy is the culmination of
            repeated years of slogging through the conventional enterprise
            models of thin margins, exhausted IT specialists and cybersecurity,
            stressed business executives, misaligned goals, and loss-making IT
            firms. Most suppliers/ vendors/ even MSPs might lead a client to a
            solution limited to their own products whether it is the right
            solution or not. <br></br>We will provide you with the best
            available solution as we are not beholden to any specific vendor or
            product.
          </p>
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
