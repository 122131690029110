import "./App.css";
import NavBar from "./components/navbar/NavBar";
import Home from "./pages/home/Home";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AboutUs from "./pages/aboutus/AboutUs";
import Footer from "./components/footer/Footer";
import Services from "./pages/services/Services";
import Technologies from "./pages/technologies/Technologies";
import Verticals from "./pages/verticals/Verticals";
import Contact from "./pages/contact/Contact";
import Solutions from "./pages/solutions/Solutions";
function App() {
  return (
    <>
      <title>Uptimize Consulting </title>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" caseSensitive={false} element={<Home />} />
          {/* <Route path="/about" caseSensitive={false} element={<AboutUs />} />
          <Route path="/services" caseSensitive={false} element={<Services />} />
          <Route path="/technologies" caseSensitive={false} element={<Technologies />} />
          <Route path="/verticals" caseSensitive={false} element={<Verticals />} />
          <Route path="/contact" caseSensitive={false} element={< Contact />} />
          <Route path="/solutions" caseSensitive={false} element={< Solutions />} /> */}
        </Routes>
      </Router>
      <div className="copyright">
        <h5>Copyright © 2023 UPTIMIZE CONSULTING LLC. All Rights Reserved.</h5>
      </div>
      {/* <Router>
        <Routes>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={AboutUs} />

        </Routes>
      </Router> */}
    </>
  );
}

export default App;
