import React, { useState } from "react";
import "./OurExpertise.css";
import { Icon } from "@iconify/react";

import cyber from "../../../../data/image/ourexpe/cyber.jpeg";
import cloud from "../../../../data/image/ourexpe/clode.jpeg";
import int from "../../../../data/image/ourexpe/in.jpeg";
import { Modal, Button, Group } from "@mantine/core";
import telco from "./telco.jpeg";

const OurExpertise = () => {
  const [showCyber, setShowCyber] = useState(false);
  const [showIT, setShowIT] = useState(false);
  const [showCloud, setShowCloud] = useState(false);
  const [showTelco, setShowTelco] = useState(false);
  const [showUcassa, setShowshowUcassa] = useState(false);
  const handleClose = () => setShowCyber(false);

  const handleShow = () => setShowCyber(true);

  const handleCloseIT = () => setShowIT(false);
  const handleCloseTelco = () => setShowTelco(false);
  const handleCloseCassa = () => setShowshowUcassa(false);

  return (
    <div className="our_expertise_main">
      <div className="ourexpertise">
        <div className="header">
          <h1>Team of Professionals</h1>
          <p>
            Our experts hold the highest degree of accountability to ensure all
            our clients are completely satisfied. We believe in honesty,
            integrity, hard work, and dedication as fundamental cues to
            achieving excellence. We have deep expertise in the following areas:{" "}
          </p>
        </div>

        <div className="columns is-variable is-2-mobile is-1-tablet is-3-desktop is-8-widescreen is-2-fullhd">
          <div className="column">
            <div className="card_ex">
              <figure className="card__thumb">
                <img
                  src="https://www.cyberdb.co/wp-content/uploads/2021/10/infra-blog-scaled.jpeg"
                  alt="Picture by Nathan Dumlao"
                  className="card__image"
                />
                <figcaption className="card__caption">
                  <h2 className="card__title">MANAGED IT</h2>
                  <p className="card__snippet">
                    Hiring a managed service provider can help an organization
                    improve its operations. SMBs are typical MSP customers. Many
                    smaller companies have limited in-house IT capabilities, so
                    they may view an MSP's service offering to obtain IT
                    expertise. But larger enterprises may also contract with
                    MSPs to handle the complex, consuming or repetitive work
                    involved in the management of IT infrastructure or end-user
                    systems.
                  </p>
                  <a
                    href
                    className="card__button"
                    onClick={() => setShowIT(true)}
                  >
                    Read more
                  </a>
                </figcaption>
              </figure>
            </div>
          </div>

          <div className="column">
            <div className="container_ex ">
              <div className="card_ex">
                <figure className="card__thumb">
                  <img
                    src={cyber}
                    alt="Picture by Kyle Cottrell"
                    className="card__image"
                  />
                  <figcaption className="card__caption">
                    <h2 className="card__title">Cyber Security</h2>

                    <p className="card__snippet">
                      Cyber consistently ranks in the top three enterprise risks
                      across industries – a view shared by those on the Board
                      and Executive Committee as well as those tasked with
                      managing cyber risk. There is an increased understanding
                      in all industries of how IP is vulnerable and customer
                      trust is fragile.
                    </p>
                    <a href onClick={handleShow} className="card__button">
                      Read more
                    </a>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className="columns is-variable is-1-mobile is-0-tablet is-3-desktop is-8-widescreen is-2-fullhd">
          <div className="column">
            <div className="card_ex">
              <figure className="card__thumb">
                <img
                  src={int}
                  alt=" Picture by Nathan Dumlao"
                  className="card__image"
                />
                <figcaption className="card__caption">
                  <h2 className="card__title">UCaaS/CCaaS</h2>
                  <p className="card__snippet">
                    Unified Communications as a Service (UCaaS) is becoming the
                    mainstream choice for most organizations. Uptimize
                    Consulting partners with the world's leading UCaaS and CCaaS
                    providers, and can address concerns about security,
                    redundancy, quality of experience, and more.
                  </p>
                  <a
                    href
                    className="card__button"
                    onClick={() => setShowshowUcassa(true)}
                  >
                    Read more
                  </a>
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="column">
            <div className="card_ex">
              <figure className="card__thumb">
                <img
                  src={cloud}
                  alt="Picture by Daniel Lincoln"
                  className="card__image"
                />
                <figcaption className="card__caption">
                  <h2 className="card__title te">
                    Cloud / Hybrid Cloud
                    <br /> & Data Centers{" "}
                  </h2>
                  <p className="card__snippet">
                    Where are your servers and infrastructures located today? Do
                    your facilities include proper cooling and power redundancy?
                    Are your compliancy needs being met? We will assist you to
                    safeguard your mission-critical applications and systems in
                    fully compliant data centers with redundant power, cooling,
                    and connectivity with colocation and/or cloud.
                  </p>
                  <a
                    href
                    className="card__button"
                    onClick={() => setShowCloud(true)}
                  >
                    Read more
                  </a>
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="column">
            <div className="card_ex">
              <figure className="card__thumb">
                <img
                  src={telco}
                  alt="Picture by Nathan Dumlao"
                  className="card__image"
                />
                <figcaption className="card__caption">
                  <h2 className="card__title te">Telco and SD-WAN / SASE</h2>
                  <p className="card__snippet">
                    Software Defined WAN (SD-WAN) is becoming the mainstream
                    choice for many organizations. Does using Internet facing
                    circuits instead of private circuits sound intimidating? It
                    does not have to be. Uptimize Consulting partners with the
                    world's leading SD-WAN and SASE providers, and can address
                    concerns about security, redundancy, quality of experience,
                    and more.
                  </p>
                  <a
                    href
                    className="card__button"
                    onClick={() => setShowTelco(true)}
                  >
                    Read more
                  </a>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          opened={showCyber}
          onClose={() => handleClose(false)}
        >
          <div className="modal_header">
            <h1 className="modal_header_main">Cyber Security</h1>
          </div>

          <p className="modal_UCaas_text">
            <p>
              Cyber consistently ranks in the top three enterprise risks across
              industries – a view shared by those on the Board and Executive
              Committee as well as those tasked with managing cyber risk. There
              is an increased understanding in all industries of how IP is
              vulnerable and customer trust is fragile.
            </p>
            <br />
            <p>
              Comprehensive security solutions are more than having a firewall
              in place and a locked front door. The heartbeat of your business
              is your customer data and financial exposure, and every day we see
              front page headlines for data breaches, even at Fortune 500
              enterprises. Small businesses are attacked daily as well, and
              often the damages are so devastating that they never recover, both
              in terms of financial losses and reputation. At Uptimize
              Consulting LLC, we recognize that security is also more than
              technology... it's about the technical solutions, processes to
              evaluate and react to potential threats, vulnerabilities, and
              impacts, plus user training and education.{" "}
            </p>
            <p className="header_modal_text">KEY SECURITY SERVICES</p>
            <p>
              <ul>
                <li>✓ Penetration Testing & Environment Audits </li>
                <li>✓ Full Security Operations Center (SOC) as a Service</li>
                <li>
                  ✓ Physical security protection (IP cameras / door entry /
                  video)
                </li>
                <li>✓ Distributed Denial of Service (DDos) Mitigation</li>
                <li>✓ Virus, Malware, Spyware, Email Scanning</li>
                <li>✓ Compliancy Attestations </li>
                <li>✓ Endpoint & Device Security Management</li>
                <li>
                  ✓ Virtual Chief of Information Security Officer (CISO)
                </li>{" "}
                <li>✓ Disaster Recovery and Backups</li>{" "}
                <li>✓ Data Loss Prevention (DLP)</li>
                <li>✓ East/West Traffic Monitoring & Breach Protection</li>
              </ul>
            </p>
            <p className="header_modal_text">WHY WORK WITH US?</p>
            <ul>
              <li>✓ Is independent & can deliver multiple options</li>
              <li>✓ Will learn your business structure and goals</li>
              <li>✓ Will understand your IT infrastructure</li>

              <li>✓ Will determine the optimal Colocation solution</li>
              <li>✓ Will research to source the optimal supplier</li>
              <li>
                ✓ Post-implementation support, including customer service and
                escalations
              </li>

              <li>✓ Be integrated as part of your organization's ecosystem</li>

              <li>
                ✓ Post-implementation support, including customer service and
                escalations
              </li>

              <li>✓ Be integrated as part of your organization's ecosystem</li>
            </ul>
          </p>
        </Modal>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          opened={showIT}
          onClose={() => handleCloseIT(false)}
        >
          <div className="modal_header">
            <h1 className="modal_header_main">MANAGED IT</h1>
          </div>

          <p className="modal_UCaas_text">
            <p className="modal_UCaas_text">
              <p>
                <p>
                  Hiring a managed service provider can help an organization
                  improve its operations. SMBs are typical MSP customers. Many
                  smaller companies have limited in-house IT capabilities, so
                  they may view an MSP's service offering to obtain IT
                  expertise. But larger enterprises may also contract with MSPs
                  to handle the complex, consuming or repetitive work involved
                  in the management of IT infrastructure or end-user systems.
                </p>
                <br />
                Key advantages of leveraging our Managed Infrastructure
                Solutions include:
                <p></p>
                {/* <p className='header_modal_text'>WHY CHOOSE UCAAS?</p> */}
              </p>
              <p className="header_modal_text">
                <ul>
                  <li>✓ Agility, Flexibility, And Scalability</li>
                  <li>✓ Round The Clock Monitoring and Alerting</li>
                  <li>✓ Infrastructure and Application Transfer</li>
                  <li>✓ Software-Defined Strategy and Planning</li>
                  <li>✓ Enterprise Continuity and Recovery</li>
                  <li>✓ Predictable Monthly Information Technology Costs</li>
                  <li>✓Cloud Platform Management</li>
                </ul>
              </p>
              <p>Our Managed Infrastructure Services include:</p>
              <ul className="header_modal_text">
                <li>
                  ✓ Server Management: preserves dependable infrastructures to
                  increase employee productivity.
                </li>
                <li>
                  ✓ Storage Management: as your information storage management
                  partner, we solve virtually all the challenges associated with
                  backup and business continuity.
                  <br /> Our cloud solution ensures you have the necessary
                  access to your valuable data anytime, anywhere.
                </li>
                <li>
                  ✓ Network: our digital transformation introduces a modern
                  network management solution. <br /> We assist our customers
                  with network solutions that generate huge benefits regarding
                  automation, management, etc.
                </li>
              </ul>
            </p>
          </p>
        </Modal>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          opened={showUcassa}
          onClose={() => setShowshowUcassa(false)}
        >
          <div className="modal_header_UCaas">
            <h1 className="modal_header_main"> UCaas/CCaaS</h1>
          </div>

          <p className="modal_UCaas_text">
            <p>
              ied Communications as a Service (UCaaS) is becoming the mainstream
              choice for most organizations. Uptimize Consulting partners with
              the world's leading UCaaS and CCaaS providers, and can address
              concerns about security, redundancy, quality of experience, and
              more.
            </p>
            <br />
            <p>
              Today every organization, regardless of size or industry, has
              sophisticated communications tools at its core. These tools can
              often be expensive and complicated, requiring an upfront
              investment of capital and regular support staff. What if there was
              a way of achieving this same level of integration and
              collaboration but simpler, more flexible and more cost effective?
              UCaaS can help make this goal a reality using seamless
              integrations with business processes at a lower price-point than
              traditional premises-based solutions.
              <p></p> <p className="header_modal_text">WHY CHOOSE UCAAS?</p>{" "}
            </p>
            <p>
              <ul>
                <li>✓ Efficient collaboration</li>
                <li>
                  ✓ Enhanced mobile and remote user experience ✓ Flexible and
                  scalable to meet your needs
                </li>
                <li>✓ Immediate cost savings and optimized billing</li>
                <li>✓ Fast implementation and easy upgrades</li>
                <li>✓ IT staff can prioritize other business needs</li>
                <li>✓ Improved customer contact experience</li>
                <li>✓ Faster third-party integrations</li>
                <li>✓ Business continuity and security</li>
                <li>✓ Faster problem solving with expert tech support</li>
              </ul>
            </p>
            <p className="header_modal_text">WHY WORK WITH US?</p>
            <ul>
              <li>
                ✓ Is independent & can deliver multiple options ✓ Will learn
                your business structure and goals
              </li>
              <li>✓ Will understand your IT infrastructure</li>
              <li>✓ Will determine the optimal UCaaS solution</li>
              <li>✓ Will research to source the optimal supplier</li>
              <li>✓ Will implement and support the selected solution</li>
              <li>✓ Be integrated as part of your organization's ecosyste</li>
              <li>
                ✓ Post-implementation support, including customer service and
                escalations
              </li>
            </ul>
          </p>
        </Modal>

        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          opened={showTelco}
          onClose={() => handleCloseTelco(false)}
        >
          <div className="modal_header_telco">
            <h1 className="modal_header_main"> Telco and SD-WAN / SASE</h1>
          </div>
          <p className="modal_UCaas_text">
            <p>
              Software Defined WAN (SD-WAN) is becoming the mainstream choice
              for many organizations. Does using Internet facing circuits
              instead of private circuits sound intimidating? It does not have
              to be. Uptimize Consulting partners with the world's leading
              SD-WAN and SASE providers, and can address concerns about
              security, redundancy, quality of experience, and more.
            </p>
            <br />
            <p>
              Your business requires a robust communications and data network
              for its mission-critical applications and services. Traditionally,
              that has been accomplished by connecting private sites with MPLS
              or other private circuits. SD-WAN provides solutions that not only
              meet or exceed those offered by traditional WAN technologies, but
              can provide additional capabilities, such as redundancy,
              application acceleration, dynamic bandwidth to large cloud
              providers, and more. Map and scale to your business objectives
              faster and more effectively, with SD-WAN.
              <p className="header_modal_text">WHY CHOOSE SD- WAN?</p>{" "}
            </p>
            <p className="header_modal_text">
              <ul>
                <li>✓ Best overall value and functionality</li>
                <li>✓ Agility and speed when adding locations</li>
                <li>✓ Redundancy, scalability and fault tolerance </li>
                <li>✓ Quality of experience for critical applications</li>
                <li>✓ Simplifcation of operations</li>
                <li>✓ Connectivity to other cloud-based solutions</li>
                <li>✓ Dynamic bandwidth</li>
                <li>✓ Cost effective upgrades</li>
                <li>✓ Support whenever you need it</li>
                <li>✓ Application acceleration and optimization</li>
              </ul>
            </p>
            <p className="header_modal_text">WHY WORK WITH US?</p>
            <ul>
              <li>✓ Is independent & can deliver multiple options</li>
              <li>✓ Will learn your business structure and goals </li>
              <li>✓ Will understand your IT infrastructure</li>
              <li>✓ Will determine the optimal SD-WAN solution</li>
              <li>✓ Will research to source the optimal supplier</li>
              <li>✓ Will implement and support the selected solution</li>
              <li>
                ✓ Post-implementation support, including customer service and
                escalations
              </li>
              <li>✓ Be integrated as part of your organization's ecosystem</li>
            </ul>
          </p>
        </Modal>

        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          opened={showCloud}
          onClose={() => setShowCloud(false)}
        >
          <div className="modal_header_clud">
            <h1 className="modal_header_main">
              {" "}
              Cloud / Hybrid Cloud & Data Centers{" "}
            </h1>
          </div>

          <p className="header_modal_text">
            <p>
              Where are your servers and infrastructures located today? Do your
              facilities include proper cooling and power redundancy? Are your
              compliancy needs being met? We will assist you to safeguard your
              mission-critical applications and systems in fully compliant data
              centers with redundant power, cooling, and connectivity with
              colocation and/or cloud.
            </p>
            <br />
            <p>
              As your trusted IT and telecom advocate, Uptimize Consulting can
              help your business strategize, source and implement the best
              colocation and cloud solutions for your business. Our primary goal
              is to ensure that you receive the best possible solution that maps
              and scales to your business objectives.{" "}
            </p>
            <p className="header_modal_text">WHY CHOOSE COLOCATION?</p>
            <p>
              <ul>
                <li>✓ Frees up space, budget, and capital expenditures</li>
                <li>✓ Flexible & scalable to accommodate business needs</li>
                <li>✓ Provides fully redundant power and cooling </li>
                <li>✓ Meets auditing and compliancy needs</li>
                <li>
                  ✓ Flexible locations for production and disaster recovery
                  workloads
                </li>
                <li>
                  ✓ Protects mission-critical data and systems with 24/7
                  security
                </li>
                <li>
                  ✓ Provides hybrid cloud options for ultimate flexibility
                </li>
              </ul>
            </p>
            <p className="header_modal_text">WHY WORK WITH US?</p>
            <ul>
              <li>✓ Is independent & can deliver multiple options</li>
              <li>✓ Will learn your business structure and goals</li>
              <li>✓ Will understand your IT infrastructure</li>
              <li>
                ✓ Will determine the optimal Colocation solution ✓ Will research
                to source the optimal supplier
              </li>
              <li>
                ✓ Post-implementation support, including customer service and
                escalations
              </li>
              <li>✓ Be integrated as part of your organization's ecosystem</li>
              <li>
                ✓ Post-implementation support, including customer service and
                escalations
              </li>
              <li>✓ Be integrated as part of your organization's ecosystem</li>
            </ul>
          </p>
        </Modal>
      </div>
    </div>
  );
};

export default OurExpertise;
